<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <h1>User Statistics</h1>
      <!-- Date Picker Row -->
      <b-row class="mt-3">
        <b-col md="2">
          <label for="start-date">Start date</label>
          <b-form-datepicker
            id="start_date"
            v-model="startDate"
            class="mb-2"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              date: '2-digit',
            }"
          ></b-form-datepicker>
        </b-col>
        <b-col md="2">
          <label for="end_date">End date</label>
          <b-form-datepicker
            id="end_date"
            v-model="endDate"
            class="mb-2"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              date: '2-digit',
            }"
          ></b-form-datepicker>
        </b-col>
        <b-col md="2" class="pt-2">
          <b-button variant="success" class="mt-4" @click="getUserStats"
            >Search</b-button
          >
        </b-col>
      </b-row>
      <!-- User Stats Row -->
      <b-row class="mt-3">
        <b-col md="4">
          <b-card title="Total Users" sub-title="Total users">
            <b-card-text>
              <span class="display-4 text-primary">{{ totalUsers }}</span>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card title="Active Users" sub-title="Active users of MyFarm app">
            <b-card-text>
              <span class="display-4 text-success">{{ activeUsers }}</span>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card
            title="Inactive Users"
            sub-title="Inactive users of MyFarm app"
          >
            <b-card-text>
              <span class="display-4 text-danger">{{ inactiveUsers }}</span>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <!-- User List Row -->
      <b-row class="mt-4">
        <b-col md="6">
          <b-row class="mb-4">
            <b-col md="2">
              <!-- Select User Per Page  -->
              <b-form-select
                v-model="item_per_page_active_users"
                @change="changeItemNumberActiveUsersList"
              >
                <b-form-select-option value="10">10</b-form-select-option>
                <b-form-select-option value="50">50</b-form-select-option>
                <b-form-select-option value="100">100</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col md="10">
              <!-- Filter -->
              <b-form-group label="" label-for="filter-input" class="mb-0">
                <b-input-group>
                  <b-form-input
                    id="filter-input"
                    v-model="filterActiveUsersList"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button
                      :disabled="!filterActiveUsersList"
                      @click="filterActiveUsersList = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            id="my-table"
            class="dataTable rounded-left"
            :outlined="true"
            :items="activeUsersList"
            :fields="fields"
            :filter="filterActiveUsersList"
            :per-page="perPageActiveUsersList"
            :current-page="currentPageActiveUsersList"
            :sort-by.sync="sortByActiveUsersList"
            :sort-desc.sync="sortDescActiveUsersList"
            head-variant="none"
            table-variant="none"
            :caption-top="true"
            :busy="isActiveTableBusy"
            show-empty
          >
            <template #empty="scope">
              <h4>{{ scope.emptyText }}</h4>
            </template>
            <template #table-caption>Active Users List</template>
            <template #cell(#)="data">
              <span v-if="currentPageActiveUsersList == 1">{{
                data.index + 1
              }}</span>
              <span v-else>{{
                (currentPageActiveUsersList - 1) * item_per_page_active_users +
                  (data.index + 1)
              }}</span>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <div class="d-flex justify-content-center mt-4">
            <b-pagination
              pills
              v-model="currentPageActiveUsersList"
              :total-rows="activeUsersTableRows"
              :per-page="perPageActiveUsersList"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </b-col>

        <b-col md="6">
          <b-row class="mb-4">
            <b-col md="2">
              <!-- Select User Per Page  -->
              <b-form-select v-model="item_per_page" @change="changeItemNumber">
                <b-form-select-option value="10">10</b-form-select-option>
                <b-form-select-option value="50">50</b-form-select-option>
                <b-form-select-option value="100">100</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col md="10">
              <!-- Filter -->
              <b-form-group label="" label-for="filter-input" class="mb-0">
                <b-input-group>
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            id="my-table"
            class="dataTable rounded-left"
            :outlined="true"
            :items="inactiveUsersList"
            :fields="fields"
            :filter="filter"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            head-variant="none"
            table-variant="none"
            :caption-top="true"
            :busy="isInactiveTableBusy"
            show-empty
          >
            <template #empty="scope">
              <h4>{{ scope.emptyText }}</h4>
            </template>
            <template #table-caption>Inactive Users List</template>
            <template #cell(#)="data">
              <span v-if="currentPage == 1">{{ data.index + 1 }}</span>
              <span v-else>{{
                (currentPage - 1) * item_per_page + (data.index + 1)
              }}</span>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <div class="d-flex justify-content-center mt-4">
            <b-pagination
              pills
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item_per_page: 10,
      item_per_page_active_users: 10,
      perPage: 10,
      perPageActiveUsersList: 10,
      currentPage: 1,
      currentPageActiveUsersList: 1,
      sortBy: "name",
      sortDesc: false,
      sortByActiveUsersList: "name",
      sortDescActiveUsersList: false,
      filter: null,
      filterActiveUsersList: null,
      startDate: "",
      endDate: "",
      activeUsers: "",
      inactiveUsers: "",
      totalUsers: "",
      fields: [
        "#",
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "phone",
          label: "Phone No.",
        },
        {
          key: "email",
          label: "Email",
        },
      ],
      activeUsersList: [],
      inactiveUsersList: [],
      isInactiveTableBusy: false,
      isActiveTableBusy: false,
    };
  },

  computed: {
    rows() {
      return this.inactiveUsersList.length;
    },

    activeUsersTableRows() {
      return this.activeUsersList.length;
    },
  },

  mounted() {
    this.getUserStats();
  },

  methods: {
    changeItemNumber() {
      this.perPage = this.item_per_page;
    },

    changeItemNumberActiveUsersList() {
      this.perPageActiveUsersList = this.item_per_page_active_users;
    },

    getUserStats() {
      this.isInactiveTableBusy = true;
      this.isActiveTableBusy = true;
      window.axios
        .get("app-usage/statistics", {
          params: {
            end: this.endDate,
            start: this.startDate,
          },
        })
        .then((resp) => {
          let userStats = resp.data.data;
          this.activeUsers = userStats.activeUsersCount;
          this.inactiveUsers = userStats.inActiveUsersCount;
          this.totalUsers = this.activeUsers + this.inactiveUsers;
          this.inactiveUsersList = userStats.inactiveUsers;
          this.activeUsersList = userStats.activeUsers;
          this.isInactiveTableBusy = false;
          this.isActiveTableBusy = false;
        });
    },
  },
};
</script>

<style scoped>
/deep/ .dataTable {
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/deep/ .dataTable thead {
  background-color: #e6e6e6;
  height: 51px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: "Poppins", sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
}

/deep/ .dataTable {
  text-align: center;
  margin-bottom: 21.5px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
/deep/ .dataTable tr td {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}

/deep/ .dataTable caption {
  border-radius: 10px 10px 0 0;
  height: 64px;
  width: 100%;
  background-color: #dcffd9;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 3.5%;
  padding-left: 27px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 25px;
}
</style>
